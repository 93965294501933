import React from 'react';
import './App.css';
import './admin.css';
import './mobile.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import Main from './component/main';
import LoginPage from './component/login';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Design from './component/design';
import Product from './component/product';
import Test from './component/test';
import Preview from './component/preview';
import Cart from './component/cart';
import Order from './component/order';
import Designadd from './admin/designadd';
import ProductAdd from './admin/productadd';
import AdminDashboard from './admin/AdminDashboard';
import Adminproduct from './admin/Adminproduct';
import AdminDesign from './admin/AddminDesign';
import AdminEditDesign from './admin/AdminEditDesign';
import AdminEditProduct from './admin/AdminEditProduct';

function App() {
  return (
   
    <div className="App">
    <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/home" element={<Product />} />
          <Route path="design/:productId" element={<Design />} />
          <Route path="preview/:did" element={<Preview />} />
          <Route path="cart" element={<Cart />} />
          <Route path="view-order" element={<Order />} />
          <Route path="design-add" element={<Designadd />} />
          <Route path="test" element={<Test />} />
          

        
        
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/design" element={<Designadd />} />
          <Route path="admin/product" element={<ProductAdd />} />
          <Route path="admin/product-details" element={<Adminproduct />} />
          <Route path="admin/design-details" element={<AdminDesign />} />
          <Route path="/designs/edit/:id" element={<AdminEditDesign />} />
          <Route path="/products/edit/:productId" element={<AdminEditProduct />} />

        </Routes>
      </div>
    
  );
}

export default App;
