import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from './header';
import Top from './top';
import { Link } from 'react-router-dom';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const Design = () => {
  const { productId } = useParams();
  const [designs1, setDesigns1] = useState([]);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    // Fetch product details and related designs based on productId
    const fetchProductDetails = async () => {
      try {
        const productResponse = await axios.get(`${baseURL}/product/${productId}`);
        setProduct(productResponse.data); // Set product details

        const designsResponse = await axios.get(`${baseURL}/product/design1/${productId}`);
       
        setDesigns1(designsResponse.data.designs || []); // Ensure designs is an array
      } catch (error) {
        console.error('Error fetching product details:', error);
      }
    };

    fetchProductDetails();
  }, [productId]);

  return (
    <React.Fragment>
      <Header />
      <Top />
      {product && (
        <div className='container'>
        <ul className='ul-list'>
        <li><Link to={`/home`}>Home</Link></li>
        <li>&nbsp; /</li>
        <li>&nbsp; {product.pname}</li>
        </ul>
        </div>
       )}
      
      <div className='container-fluid design-container'>
       
       

        <div className='row design-row'>
          
       
          {designs1.map(design => (
            <div key={design.did} className='col-sm-3 design_div'>
              <Link to={`/preview/${design.did}`}>
                <img src={`${baseURL}/images/${design.images}`} alt={design.dname} className='design_image' />
                <p className='design_name'>{design.dname}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <br />
    </React.Fragment>
  );
};

export default Design;
