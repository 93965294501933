import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import AdminLayout from './AdminLayout';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const EditDesign = () => {
  const { id } = useParams(); // Get ID from route parameters
  const [design, setDesign] = useState(null);
  const [formData, setFormData] = useState({
    product: '',
    dname: '',
    size: '',
    printing_side: '',
    paper_type: '',
    foil: '',
    quantity: '',
    note: ''
  });
  const [image, setImage] = useState(null); // State to handle new image file
  const [productList, setProductList] = useState([]); // State to handle product list
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDesign = async () => {
      try {
        const response = await axios.get(`${baseURL}/designs/${id}`);
        setDesign(response.data);
        setFormData({
          product: response.data.product || '',
          dname: response.data.dname || '',
          size: response.data.size || '',
          printing_side: response.data.printing_side || '',
          paper_type: response.data.paper_type || '',
          foil: response.data.foil || '',
          quantity: response.data.quantity || '',
          note: response.data.note || ''
        });
      } catch (err) {
        setError(err.message);
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${baseURL}/products`);
        setProductList(response.data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchDesign();
    fetchProducts();
  }, [id]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updateData = new FormData();
    updateData.append('product', formData.product);
    updateData.append('dname', formData.dname);
    updateData.append('size', formData.size);
    updateData.append('printing_side', formData.printing_side);
    updateData.append('paper_type', formData.paper_type);
    updateData.append('foil', formData.foil);
    updateData.append('quantity', formData.quantity);
    updateData.append('note', formData.note);
    if (image) {
      updateData.append('image', image);
    }

    try {
      await axios.put(`${baseURL}/designs/${id}`, updateData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Design updated successfully');
      navigate('/admin/design-details');
    } catch (err) {
      setError('Failed to update design');
    }
  };

  return (
    <AdminLayout>
      <div className="container">
        <h3>Edit Design</h3>
        {error && <p className="error">{error}</p>}
        {design ? (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Product</label>
              <select
                name="product"
                value={formData.product}
                onChange={handleChange}
                className="form-control"
                required
              >
                <option value="">Select Product</option>
                {productList.map((product) => (
                  <option key={product.id} value={product.pid}>
                    {product.pname}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Design Name</label>
              <input
                type="text"
                name="dname"
                value={formData.dname}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
            <div className="form-group">
              <label>Current Image</label>
              {design.image && (
                <div className="mt-3">
                  <img
                    src={`${baseURL}/images/${design.image}`}  // Fixed image path
                    alt="Current Design"
                    className="img-fluid"
                    style={{ maxWidth: '200px' }}
                  />
                </div>
              )}
            </div>
            <div className="form-group">
              <label>New Image</label>
              <input
                type="file"
                name="image"
                onChange={handleFileChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Size</label>
              <input
                type="text"
                name="size"
                value={formData.size}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
            <div className="form-group">
              <label>Printing Side</label>
              <input
                type="text"
                name="printing_side"
                value={formData.printing_side}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
            <div className="form-group">
              <label>Paper Type</label>
              <input
                type="text"
                name="paper_type"
                value={formData.paper_type}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
            <div className="form-group">
              <label>Foil</label>
              <input
                type="text"
                name="foil"
                value={formData.foil}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Quantity</label>
              <input
                type="number"
                name="quantity"
                value={formData.quantity}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Note</label>
              <textarea className="form-control" name="note" value={formData.note} onChange={handleChange}></textarea>
            </div>
            <button type="submit" className="btn btn-primary">Update</button>
          </form>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </AdminLayout>
  );
};

export default EditDesign;
