import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './desktop.css';
import './style/fonts.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <React.Fragment>
  <BrowserRouter>
  <App />  
  </BrowserRouter>
  </React.Fragment>,
  document.getElementById('root')
);

reportWebVitals();
