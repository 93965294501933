import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './header';
import Cookies from 'js-cookie';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const logincheck = () => {
    if (username === 'Admin' && password === 'Admin@24') {
      Cookies.set('user', 'Admin', { expires: 1 }); // Cookie expires in 1 day
      navigate('/admin/dashboard');
    } else if (username === 'Mandira' && password === 'Mandira@24') {
      Cookies.set('user', 'Mandira', { expires: 1 }); // Cookie expires in 1 day
      navigate('/home');
    } else {
      setMessage('Not authorised!');
    }
  };

  return (
    <>
      <Header />
      <div className='container login-container'>
        <div className='login-card'>
          
          <div className="card-body card-login">
          <p className='login-title-card'>LOGIN</p>
            <p className='login-notification'>{message}</p>
            <div className='form-group row card-row'>
              <label>Enter Username:</label>
              <input 
                type='text' 
                className='form-control' 
                value={username} 
                onChange={(e) => setUsername(e.target.value)} 
                placeholder='' 
              />
              <br />
              <br />
              <label>Enter Password:</label>
              <input 
                type='password' 
                className='form-control' 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                placeholder='' 
              />
              <br />
              <br />
            </div>
            <div className='card-button-row row'>
              <button className='login-button' onClick={logincheck}>Submit</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
