import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

function AdminNavbar() {
  const user = Cookies.get('user');
  const navigate = useNavigate();
  const handleLogout = () => {
    Cookies.remove('user');
    navigate('/login');
  };
  return (
    <div className="admin-navbar">
      <Link to="/admin/dashboard">Mandira's</Link>
      <nav>
        <ul>
          {/* <li><Link to="/profile">Profile</Link></li> */}
          <li><p className="logout-title" onClick={handleLogout}>Logout</p></li>
        </ul>
      </nav>
    </div>
  );
}

export default AdminNavbar;
