import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './header';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap'; 
import 'bootstrap/dist/css/bootstrap.min.css';

const Product = () => {
  const [order, setOrder] = useState([]);
  const [designs, setDesigns] = useState({});
  const [products, setProducts] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page
  const [selectedImage, setSelectedImage] = useState(''); 
  const [showImageModal, setShowImageModal] = useState(false); 

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${baseURL}/order-fetch`);
        const orders = response.data.order;
        setOrder(orders);

        // Extract unique design and product IDs
        const designIds = [...new Set(orders.map(item => item.did))];
        const productIds = [...new Set(orders.map(item => item.pid))];
        
        await Promise.all([
          fetchDesignDetails(designIds),
          fetchProductDetails(productIds)
        ]);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setError('Error fetching orders');
        setLoading(false);
      }
    };

    const fetchDesignDetails = async (designIds) => {
      try {
        const designPromises = designIds.map(id => axios.get(`${baseURL}/cart-fetch-design/${id}`));
        const designResponses = await Promise.all(designPromises);
        const designMap = {};
        designResponses.forEach(response => {
          if (response.data && response.data.design && response.data.design.length > 0) {
            const design = response.data.design[0];
            designMap[design.did] = design;
          }
        });
        setDesigns(designMap);
      } catch (error) {
        console.error('Error fetching design details:', error);
        setError('Error fetching design details');
      }
    };

    const fetchProductDetails = async (productIds) => {
      try {
        const productPromises = productIds.map(id => axios.get(`${baseURL}/product-fetch/${id}`));
        const productResponses = await Promise.all(productPromises);
        const productMap = {};
        productResponses.forEach(response => {
          if (response.data && response.data.product) {
            const product = response.data.product;
            productMap[product.pid] = product;
          }
        });
        setProducts(productMap);
      } catch (error) {
        console.error('Error fetching product details:', error);
        setError('Error fetching product details');
      }
    };

    fetchOrders();
  }, [baseURL]);

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      if (isNaN(date)) {
        throw new Error('Invalid date');
      }
      return format(date, 'd MMMM, yyyy, h:mm:ss a');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  // Search and filter orders
  const filteredOrders = order.filter(item =>
    (item.oid.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
    (designs[item.did]?.dname.toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
    (products[item.pid]?.pname.toLowerCase().includes(searchTerm.toLowerCase()) || '')
  );

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredOrders.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleImageClick = (imagePath) => {
  

    setSelectedImage(imagePath);
    setShowImageModal(true);
  };
  const handleCloseImageModal = () => {
    setShowImageModal(false);
    setSelectedImage('');
  };
  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <React.Fragment>
      <Header />
      <div className='container ul-container'>
                   <ul className='ul-list-preview'>
                   <li><Link to={`/home`}>Home</Link></li>
                   <li>&nbsp; /</li>
                   <li>&nbsp;Orders</li>
                   </ul>
                 </div>
      <div className='container product-container'>
        <h3>Orders</h3>
        <input
          type="text"
          placeholder="Search by Order ID, Design Name, or Product Name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input-main"
        />
        <table className="table">
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Date</th>
              <th>Product Name</th>
              <th>Design Name</th>
              <th>Images</th>
              <th>Quantity</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.length > 0 ? (
              currentItems.map((item, index) => (
                <tr key={index}>
                  <td>{item.oid}</td>
                  <td>{formatDate(item.date)}</td> {/* Formatting date */}
                  <td>{products[item.pid]?.pname || 'Loading...'}</td>
                  <td>{designs[item.did]?.dname || 'Loading...'}</td>
                  <td>
                    {designs[item.did]?.images ? (
                      <img
                        src={`${baseURL}/images/${designs[item.did].images}`}
                        alt={designs[item.did].dname}
                        style={{ width: '50px', height: '50px',cursor:'pointer' }}
                        onClick={() => handleImageClick(`${baseURL}/images/${designs[item.did].images}`)}
                      />
                    ) : (
                      'No image available'
                    )}
                  </td>
                  <td>{item.quantity}</td>
                  <td>{item.status}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No orders found</td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="pagination">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>Page {currentPage} of {totalPages}</span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>


      <Modal show={showImageModal} onHide={handleCloseImageModal}>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedImage ? <img src={selectedImage} alt="Design" className="img-fluid" /> : 'No Image'}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseImageModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Product;
