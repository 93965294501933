import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

function Top() {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const user = Cookies.get('user');

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);

  return (
    <div
      className={`collapsible ${isHovered ? 'expanded' : 'collapsed'}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <p className='toggle-title'>PRODUCT</p>
      {isHovered && (
        <div className="content">
          <div className='ul-div'>
            <ul className='ul-li-div'>
              <li><Link to='/design/1'>Generic Labels</Link></li>
              <li><Link to='/design/2'>Freezer Meal Sleeves</Link></li>
              <li><Link to='/design/3'>Freezer Meal Flyer</Link></li>
              <li><Link to='/design/4'>Luggage Tags</Link></li>
            </ul>
            <ul className='ul-li-div'>
            <li><Link to='/design/7'>Chutney Lid Labels</Link></li>
            <li><Link to='/design/8'>Business Card Stickers</Link></li>
            <li><Link to='/design/9'>Chutney Jar Labels</Link></li>
            <li><Link to='/design/10'>Drinks Labels</Link></li>
            </ul>
            <ul className='ul-li-div'>
            <li><Link to='/design/11'>Samosa Stickers</Link></li>
            <li><Link to='/design/12'>Sweet Sticker</Link></li>
            <li><Link to='/design/13'>Chocolate Wrappers</Link></li>
            <li><Link to='/design/14'>Ice Cream Stickers</Link></li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default Top;
