import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AdminLayout from './AdminLayout';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const DesignForm = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    product: '',
    dname: '',
    size: '',
    printing_side: '',
    paper_type: '',
    foil: '',
    quantity: '',
    note: '',
  });
  const [image, setImage] = useState(null);
  const [notification, setNotification] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataWithImage = new FormData();
    Object.keys(formData).forEach(key => {
      formDataWithImage.append(key, formData[key]);
    });
    formDataWithImage.append('image', image);

    try {
      const response = await axios.post(`${baseURL}/insert-design`, formDataWithImage, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        setNotification('Design inserted successfully');
        setTimeout(() => setNotification(null), 3000);
      } else {
        setNotification('Failed to insert design');
        setTimeout(() => setNotification(null), 3000);
      }
    } catch (err) {
      console.error('Error inserting design:', err);
      setNotification('An error occurred while inserting design');
      setTimeout(() => setNotification(null), 3000);
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${baseURL}/`); // Replace with your actual endpoint
        setProducts(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  return (
    <AdminLayout>
      <div className="container">
        <div className='row'>
          <div className='col-sm-8'>

         
        <h3>Insert New Design</h3>
        {notification && (
          <div className="alert alert-info" role="alert">
            {notification}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Image</label>
            <input type="file" onChange={handleImageChange} className="form-control" required />
          </div>
          <div className="form-group">
            <label>Product</label>
            <select name="product" value={formData.product} onChange={handleChange} className="form-control" required>
              <option value="">Select a product</option>
              {loading && <option>Loading products...</option>}
              {error && <option>Error loading products</option>}
              {products.map(product => (
                <option key={product.pid} value={product.pid}>{product.pname}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Design Name</label>
            <input type="text" name="dname" value={formData.dname} onChange={handleChange} className="form-control" required />
          </div>
          <div className="form-group">
            <label>Size</label>
            <input type="text" name="size" value={formData.size} onChange={handleChange} className="form-control" required />
          </div>
          <div className="form-group">
            <label>Printing Side</label>
            <input type="text" name="printing_side" value={formData.printing_side} onChange={handleChange} className="form-control" required />
          </div>
          <div className="form-group">
            <label>Paper Type</label>
            <input type="text" name="paper_type" value={formData.paper_type} onChange={handleChange} className="form-control" required />
          </div>
          <div className="form-group">
            <label>Foil</label>
            <input type="text" name="foil" value={formData.foil} onChange={handleChange} className="form-control"  />
          </div>
          <div className="form-group">
            <label>Quantity</label>
            <input type="number" name="quantity" value={formData.quantity} onChange={handleChange} className="form-control"  />
          </div>
          <div className="form-group">
            <label>Note</label>
            <textarea className='form-control' name='note' value={formData.note} onChange={handleChange} ></textarea>
          </div>
          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </div>
      </div>
      </div>
    </AdminLayout>
  );
};

export default DesignForm;
