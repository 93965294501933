import React, { useState, useEffect, Component } from 'react';
import axios from 'axios';
import Header from './header';
import Top from './top';
import { Link } from 'react-router-dom';
const Product = () => {
  const [products, setProducts] = useState([]);

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
   
    axios.get(`${baseURL}/`)

    .then(response => {
        setProducts(response.data);
      })
      .catch(error => {
        console.error('Error fetching products:', error);
      });
  }, [baseURL]);

  return (
    <React.Fragment>
        <Header />
    <Top />
     
      
    <div className='container-fluid product-container'>
    
      <div className='row design-row'>
      {products.map(product => {
       

        return (
          <div key={product.pid} className='col-sm-3 product-div' >
            <Link to={`/design/${product.pid}`}>
            <img src={`${baseURL}/images/${product.image}`} alt={product.pname} className='product_image' />
              <p className='product_name'>{product.pname}</p>
              </Link>
           
           
          </div>
        );
      })}
    </div>
    </div>
    </React.Fragment>
  );
};
 

export default Product;
