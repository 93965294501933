import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

function AdminSidebar() {
  const location = useLocation();
  const [showProductSubMenu, setShowProductSubMenu] = useState(false);
  const [showDesignSubMenu, setShowDesignSubMenu] = useState(false);

  useEffect(() => {
    // Open submenu if any of its links is active
    if (location.pathname.startsWith('/admin/product')) {
      setShowProductSubMenu(true);
    }
    if (location.pathname.startsWith('/admin/design')) {
      setShowDesignSubMenu(true);
    }
  }, [location.pathname]);

  const toggleProductSubMenu = () => {
    setShowProductSubMenu(!showProductSubMenu);
  };

  const toggleDesignSubMenu = () => {
    setShowDesignSubMenu(!showDesignSubMenu);
  };

  const isActive = (path) => location.pathname === path ? 'active' : '';

  return (
    <div className="admin-sidebar">
      <ul>
        <li>
          <span className='span-paragraph' onClick={toggleProductSubMenu}>Product</span>
          {showProductSubMenu && (
            <ul>
              <li><Link to="/admin/product" className={isActive('/admin/product')}>Product</Link></li>
              <li><Link to="/admin/product-details" className={isActive('/admin/product-details')}>Product-page</Link></li>
            </ul>
          )}
        </li>
        <li>
          <span className='span-paragraph' onClick={toggleDesignSubMenu}>Design</span>
          {showDesignSubMenu && (
            <ul>
              <li><Link to="/admin/design" className={isActive('/admin/design')}>Design</Link></li>
              <li><Link to="/admin/design-details" className={isActive('/admin/design-details')}>Design-page</Link></li>
            </ul>
          )}
        </li>
        <li><Link to="/admin/dashboard" className={isActive('/admin/dashboard')}>Orders</Link></li>
        {/* Add more links as needed */}
      </ul>
    </div>
  );
}

export default AdminSidebar;
