import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import AdminLayout from './AdminLayout';
import { Modal, Button } from 'react-bootstrap'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactToPrint from 'react-to-print';


const Product = () => {
  const componentRef = useRef();

  const [order, setOrder] = useState([]);
  const [designs, setDesigns] = useState({});
  const [products, setProducts] = useState({}); // New state for products
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showImageModal, setShowImageModal] = useState(false); 
  const [selectedImage, setSelectedImage] = useState(''); 
  const itemsPerPage = 10; 
  const [orderFormData, setOrderFormData] = useState(null); 
  const [showOrderDetailsModal, setShowOrderDetailsModal] = useState(false); // State for order details modal

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    fetchOrders();
  }, [baseURL]);

  const fetchOrders = async () => {
    try {
      const response = await axios.get(`${baseURL}/order-fetch`);
      const orders = response.data.order;
      setOrder(orders);

      const designIds = [...new Set(orders.map(item => item.did))];
      const productIds = [...new Set(orders.map(item => item.pid))]; // Collect product IDs

      await Promise.all([
        fetchDesignDetails(designIds),
        fetchProductDetails(productIds) // Fetch product details
      ]);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setError('Error fetching orders');
      setLoading(false);
    }
  };

  const fetchDesignDetails = async (designIds) => {
    try {
      const designPromises = designIds.map(id => axios.get(`${baseURL}/cart-fetch-design/${id}`));
      const designResponses = await Promise.all(designPromises);
      const designMap = {};
      designResponses.forEach(response => {
        if (response.data && response.data.design && response.data.design.length > 0) {
          const design = response.data.design[0];
          designMap[design.did] = design;
        }
      });
      setDesigns(designMap);
    } catch (error) {
      console.error('Error fetching design details:', error);
      setError('Error fetching design details');
    }
  };

  const fetchProductDetails = async (productIds) => {
    try {
      const productPromises = productIds.map(id => axios.get(`${baseURL}/product-fetch/${id}`));
      const productResponses = await Promise.all(productPromises);
      const productMap = {};
      productResponses.forEach(response => {
        if (response.data && response.data.product) {
          const product = response.data.product;
          productMap[product.pid] = product;
        }
      });
      setProducts(productMap);
    } catch (error) {
      console.error('Error fetching product details:', error);
      setError('Error fetching product details');
    }
  };

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      if (isNaN(date)) {
        throw new Error('Invalid date');
      }
      return format(date, 'd MMMM, yyyy, h:mm:ss a');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  const handleOrderClick = async (orderId) => {
    try {
      setShowStatusModal(false);
      setShowImageModal(false);
      
      const response = await axios.get(`${baseURL}/order-detail/${orderId}`);
      setOrderFormData(response.data); 
      setShowOrderDetailsModal(true); 
    } catch (error) {
      console.error('Error fetching order details:', error);
      setError('Error fetching order details');
    }
  };

  const handleStatusClick = (order) => {
    setShowImageModal(false);
    setShowOrderDetailsModal(false);

    setSelectedOrder(order);
    setShowStatusModal(true);
  };

  const handleCloseStatusModal = () => {
    setShowStatusModal(false);
    setSelectedOrder(null);
  };

  const handleStatusChange = async (status) => {
    try {
      await axios.post(`${baseURL}/update-order-status`, {
        oid: selectedOrder.oid,
        status: status,
      });
      console.log(`Changed status of order ${selectedOrder.oid} to ${status}`);
      handleCloseStatusModal();
      fetchOrders(); 
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  const handleImageClick = (imagePath) => {
    setShowStatusModal(false);
    setShowOrderDetailsModal(false);

    setSelectedImage(imagePath);
    setShowImageModal(true);
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);
    setSelectedImage('');
  };

  const handleCloseOrderDetailsModal = () => {
    setShowOrderDetailsModal(false);
    setOrderFormData(null);
  };

  const filteredOrders = order.filter(item =>
    (item.oid.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
    (designs[item.did]?.dname.toLowerCase().includes(searchTerm.toLowerCase()) || '')
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredOrders.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <AdminLayout>
      <div className='container product-container'>
        <h3>Orders</h3>
        <input
          type="text"
          placeholder="Search by Order ID or Design Name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        <table className="table">
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Date</th>
              <th>Product Name</th> {/* Added column for product name */}
              <th>Design Name</th>

              <th>Note</th>
              <th>Images</th>
              <th>Quantity</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.length > 0 ? (
              currentItems.map((item, index) => (
                <tr key={index}>
                  <td onClick={() => handleOrderClick(item.oid)} style={{ cursor: 'pointer' }}>{item.oid}</td>
                  <td>{formatDate(item.date)}</td>
                  <td>{products[item.pid]?.pname || 'Loading...'}</td> {/* Display product name */}
                  <td>{designs[item.did]?.dname || 'Loading...'}</td>

                  <td>{designs[item.did]?.note}</td>
                  <td>
                    {designs[item.did]?.images ? (
                      <img
                        src={`${baseURL}/images/${designs[item.did].images}`}
                        alt={designs[item.did].dname}
                        style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                        onClick={() => handleImageClick(`${baseURL}/images/${designs[item.did].images}`)}
                      />
                    ) : (
                      'No image available'
                    )}
                  </td>
                  <td>{item.quantity}</td>
                  <td>
                    {item.status !== 'Completed' ? (
                      <>
                        <img
                          src={`${baseURL}/images/status.png`}
                          alt="status"
                          className="admin-status"
                          onClick={() => handleStatusClick(item)}
                          style={{ cursor: 'pointer' }}
                        />
                        <p>{item.status}</p>
                      </>
                    ) : (
                      <p>Completed</p>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8">No orders found</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="pagination">
          <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </button>
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={currentPage === index + 1 ? 'active' : ''}
            >
              {index + 1}
            </button>
          ))}
          <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>

      {/* Status Modal */}
      <Modal show={showStatusModal} onHide={handleCloseStatusModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Order Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button className='btn-success status-button' onClick={() => handleStatusChange('Completed')}>Complete Order</Button>
          <Button className='btn-primary status-button' onClick={() => handleStatusChange('In-Process')}>In Process</Button>
          <Button className='btn-danger status-button' onClick={() => handleStatusChange('Cancelled')}>Cancel Order</Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseStatusModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Image Modal */}
      <Modal show={showImageModal} onHide={handleCloseImageModal}>
        <Modal.Header closeButton>
          <Modal.Title>Order Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={selectedImage}
            alt="Order"
            style={{ width: '100%', height: 'auto' }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseImageModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Order Details Modal */}
      <Modal show={showOrderDetailsModal} onHide={handleCloseOrderDetailsModal}>
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body ref={componentRef}>
          {orderFormData ? (
            <div>
              <p><strong>Order ID:</strong> {orderFormData.oid}</p>
              <p><strong>Date:</strong> {formatDate(orderFormData.date)}</p>
              <p><strong>Product Name:</strong> {products[orderFormData.pid]?.pname}</p>
              <p><strong>Design Name:</strong> {designs[orderFormData.did]?.dname}</p>
              <p><strong>Size:</strong> {designs[orderFormData.did]?.size}</p>
              <p><strong>Printing Side:</strong> {designs[orderFormData.did]?.printing_side}</p>
              <p><strong>Paper Type:</strong> {designs[orderFormData.did]?.paper_type}</p>
              <p><strong>Foil:</strong> {designs[orderFormData.did]?.foil}</p>
              <p><strong>Note:</strong> {designs[orderFormData.did]?.note}</p>
              <p><strong>Quantity:</strong> {orderFormData.quantity}</p>
              {designs[orderFormData.did]?.images && (
                <img
                  src={`${baseURL}/images/${designs[orderFormData.did].images}`}
                  alt={designs[orderFormData.did].dname}
                  style={{ width: '100%', height: 'auto' }}
                />
              )}
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseOrderDetailsModal}>
            Close
          </Button>
          <ReactToPrint
            trigger={() => <Button variant="primary">Print this out!</Button>}
            content={() => componentRef.current}
          />
        </Modal.Footer>
      </Modal>
    </AdminLayout>
  );
};

export default Product;
