import React from 'react';
import AdminSidebar from './AdminSidebar';
import AdminFooter from './AdminFooter';
import AdminNavbar from './AdminNavbar';

function AdminLayout({ children }) {
  return (
    <div className="admin-layout">
      <AdminNavbar />
      <div className="admin-content">
        <AdminSidebar />
        <main>
          {children}
        </main>
      </div>
      <AdminFooter />
    </div>
  );
}

export default AdminLayout;
