import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import AdminLayout from './AdminLayout';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const ProductEdit = () => {
  const { productId } = useParams(); // Product ID from route parameters

  const [product, setProduct] = useState({
    pname: '',
    image: ''
  });
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(`${baseURL}/product/${productId}`);
        setProduct(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [productId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpdate = async () => {
    try {
      // Update product details
      const formData = new FormData();
      formData.append('pname', product.pname);
      if (file) {
        formData.append('file', file);
      }

      await axios.post(`${baseURL}/product-update/${productId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      
      navigate(`/admin/product-details`); // Redirect to product details or any other page
    } catch (err) {
      console.error('Error updating product:', err);
      alert('An error occurred while updating the product.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <AdminLayout>
      <div className="container">
        <h2>Edit Product</h2>
        <div className="form-group row">
          <div className="col-sm-4">
            <label>Product Name</label>
          </div>
          <div className="col-sm-6">
            <input
              type="text"
              className="form-control"
              name="pname"
              value={product.pname}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-4">
            <label>Current Image</label>
          </div>
          <div className="col-sm-6">
            {product.image && (
              <div className="mt-3">
                <img
                  src={`${baseURL}/images/${product.image}`}
                  alt="Current Product"
                  className="img-fluid"
                  style={{ maxWidth: '200px' }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-4">
            <label>New Image</label>
          </div>
          <div className="col-sm-6">
            <input
              type="file"
              className="form-control"
              onChange={handleFileChange}
            />
          </div>
        </div>
        <button className="btn btn-primary" onClick={handleUpdate}>
          Update Product
        </button>
      </div>
    </AdminLayout>
  );
};

export default ProductEdit;
