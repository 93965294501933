import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './header';
import Top from './top';


import {Link, useNavigate } from 'react-router-dom';
const baseURL = process.env.REACT_APP_API_BASE_URL;

const Design = () => {
  const [cart, setCart] = useState([]);
  const [designs, setDesigns] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notification, setNotification] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCart = async () => {
      try {
        const response = await axios.get(`${baseURL}/cart`);
        if (response.data && response.data.carts) {
          setCart(response.data.carts);
          // Fetch design details for each cart item
          const designIds = response.data.carts.map(item => item.design);
          await fetchDesignDetails(designIds);
        } else {
          setCart([]);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching cart details:', error);
        setError(error);
        setLoading(false);
      }
    };

    const fetchDesignDetails = async (designIds) => {
      try {
        const designPromises = designIds.map(id => axios.get(`${baseURL}/cart-fetch-design/${id}`));
        const designResponses = await Promise.all(designPromises);
        const designMap = {};
        designResponses.forEach(response => {
          if (response.data && response.data.design && response.data.design.length > 0) {
            const design = response.data.design[0];
            designMap[design.did] = design;
          }
        });
        setDesigns(designMap);
      } catch (error) {
        console.error('Error fetching design details:', error);
        setError(error);
      }
    };

    fetchCart();
  }, []);

  const deleteCart = async (cid) => {
    try {
      await axios.delete(`${baseURL}/cart-delete/${cid}`);
      setCart(cart.filter(item => item.cid !== cid));
      setNotification('Item successfully deleted!');
      setTimeout(() => setNotification(null), 3000); // Hide notification after 3 seconds
    } catch (error) {
      console.error('Error deleting cart item:', error);
      setError(error);
    }
  };
 

  const place_order = async () => {
    try {
      // Send a request to the server to place the order
      const response = await axios.post(`${baseURL}/place-order`, { cart });
      
      if (response.status === 200) {
        setNotification('Order placed successfully!');
        setTimeout(() => {
          setNotification(null);
          navigate('/home');
        }, 2000); // Redirect after 2 seconds
      }
    } catch (error) {
      console.error('Error placing order:', error);
      setError('Failed to place order.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching cart details: {error.message}</div>;

  return (
    <React.Fragment>
      <Header />
      <Top />
      <div className='container ul-container'>
                   <ul className='ul-list-preview'>
                   <li><Link to={`/home`}>Home</Link></li>
                   <li>&nbsp; /</li>
                 
                   <li>&nbsp;Cart</li>
                   </ul>
                 </div>
      <div className="container cart-container">
        <h3 className='cart-title-cart'>Cart</h3>
        <hr />
        {notification && (
          <div className="alert alert-success" role="alert">
            {notification}
          </div>
        )}
        <div className='row cart-desc-row'>
          <div className='col-sm-3'>Product</div>
          <div className='col-sm-3'>Description</div>
          <div className='col-sm-3'>Quantity</div>
          <div className='col-sm-3'>Action</div>
        </div>
        <hr />
        {cart.map((item) => (
          <div key={item.cid}>
            <div className='row row-cart'>
              <div className='col-sm-3'>
                {designs[item.design] ? (
                  <>
                    <img
                      src={`${baseURL}/images/${designs[item.design].images}`}
                      alt={designs[item.design].dname}
                      className="cart_image"
                    />
                  </>
                ) : (
                  <p>No image available</p>
                )}
              </div>
              <div className='col-sm-3'>
                {designs[item.design] ? (
                  <>
                    <p className='image-cart-name'>{designs[item.design].dname}</p>
                  </>
                ) : (
                  <p>No design details available</p>
                )}
              </div>
              <div className='col-sm-3 cart-quanity-div'>
                {item.quantity}
              </div>
              <div className='col-sm-3 cart-delete-div'>

                <img  onClick={() => deleteCart(item.cid)}
                      src={`${baseURL}/images/delete.png`}
                      className="deltesvg"
                    />
               
              </div>
            </div>
            <hr />
          </div>
        ))}
        <div className='proceed-btn-row'>
 <button className='Placed_order-btn' onClick={place_order}> Proceed </button>
 </div>
      </div>
    </React.Fragment>
  );
};

export default Design;
