import React, { useState } from 'react';

const TableWithSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  
  // Sample data for the table
  const data = [
    { name: 'John Doe', position: 'Developer', office: 'New York', age: 30, startDate: '2022/01/01', salary: '$100,000' },
    { name: 'Jane Smith', position: 'Designer', office: 'San Francisco', age: 25, startDate: '2021/05/15', salary: '$80,000' },
    // Add more rows as needed
  ];

  // Filter data based on the search term
  const filteredData = data.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.position.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.office.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.startDate.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container">
      <h2>Searchable Table</h2>
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />
      <table className="data-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Position</th>
            <th>Office</th>
            <th>Age</th>
            <th>Start Date</th>
            <th>Salary</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.position}</td>
              <td>{item.office}</td>
              <td>{item.age}</td>
              <td>{item.startDate}</td>
              <td>{item.salary}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableWithSearch;
