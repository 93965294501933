import React from 'react';

function AdminFooter() {
  return (
    <div className="admin-footer">
      <p>&copy; 2024 Your Company. All rights reserved.</p>
    </div>
  );
}

export default AdminFooter;
