import React from 'react'
import { Link } from 'react-router-dom';
import logo from './images/MK.webp';
function main() {

  return (
    <reactFragment>
        <div className='front-page-container'>
          <Link to={`/login`} ><p className='login-title'>Login</p></Link >
        <img src={logo} alt='Main-logo' className='Main-logo'/>
        </div>
    </reactFragment>
  )
}

export default main
