import React, { useState } from 'react';
import axios from 'axios';
import AdminLayout from './AdminLayout';
const ProductForm = () => {
  const [formData, setFormData] = useState({
    pid: '',
    pname: ''
  });
  const [image, setImage] = useState(null);
  const [notification, setNotification] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataWithImage = new FormData();
    Object.keys(formData).forEach(key => {
      formDataWithImage.append(key, formData[key]);
    });
    if (image) {
      formDataWithImage.append('image', image);
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/insert-product`, formDataWithImage, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        setNotification('Product inserted successfully');
        setTimeout(() => setNotification(null), 3000);
      } else {
        setNotification('Failed to insert product');
        setTimeout(() => setNotification(null), 3000);
      }
    } catch (err) {
      console.error('Error inserting product:', err);
      setNotification('An error occurred while inserting product');
      setTimeout(() => setNotification(null), 3000);
    }
  };

  return (
    <AdminLayout>
    <div className="container">
      <h3>Insert New Product</h3>
      {notification && (
        <div className="alert alert-info" role="alert">
          {notification}
        </div>
      )}
      <form onSubmit={handleSubmit}>
       
        <div className="form-group">
          <label>Product Name</label>
          <input type="text" name="pname" value={formData.pname} onChange={handleChange} className="form-control" required />
        </div>
        <div className="form-group">
          <label>Image</label>
          <input type="file" onChange={handleImageChange} className="form-control" required />
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>
    </div>
    </AdminLayout>
  );
};

export default ProductForm;
