import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
function Header() {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const [isHovered, setIsHovered] = useState(false);
  const user = Cookies.get('user');
  const [inputsearch, setInputsearch] = useState([]);

  const navigate = useNavigate();
  const handleLogout = () => {
    Cookies.remove('user');
    navigate('/login');
  };
  // const InputHandleChange = (e) =>{
  //   const value= e.target.value;
  //   setInputsearch(e.target.value);
  //    if(value.length>2){
  //     const response = axios.get(`${baseURL}/search-product?query=${value}`)
      

  //     }
  //    }
  // }

  const redirect_on_home_page = () => {
    navigate('/home');
  }



  return (
    <>
      <div className='container-fluid header-container'>

        <div className='row header-row'>
         
            <img
              src={`${baseURL}/images/madira_logo_2.webp`}
              alt='Main-logo'
              className='header-logo'
              onClick={redirect_on_home_page}
            />
        
        </div>
        { user && (
          <>
        <Link to='/view-order'>
        <img  src={`${baseURL}/images/order02.webp`} alt='cart' className='orderimage' />
         
        </Link>
        <Link to='/cart'>
         
          <img  src={`${baseURL}/images/cart-01.webp`} alt='cart' className='cartimage' />
        </Link>
        <div>
      <img src={`${baseURL}/images/logout.svg`} className="logout-button" onClick={handleLogout} />
    </div>
    </>
    )}
      </div>
     
    </>
  );
}

export default Header;
