import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AdminLayout from './AdminLayout';
import { Link } from 'react-router-dom';

const baseURL = process.env.REACT_APP_API_BASE_URL; // Ensure this is correctly set

const DesignTable = () => {
  const [designs, setDesigns] = useState([]);
  const [filteredDesigns, setFilteredDesigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  useEffect(() => {
    const fetchDesigns = async () => {
      try {
        const response = await axios.get(`${baseURL}/designs`);
        setDesigns(response.data);
        setFilteredDesigns(response.data); // Initialize filteredDesigns
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDesigns();
  }, []);

  useEffect(() => {
    // Filter designs based on search term
    const results = designs.filter(design =>
      design.product.toLowerCase().includes(searchTerm.toLowerCase()) ||
      design.dname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      design.size.toLowerCase().includes(searchTerm.toLowerCase()) ||
      design.printing_side.toLowerCase().includes(searchTerm.toLowerCase()) ||
      design.paper_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
      design.foil.toLowerCase().includes(searchTerm.toLowerCase()) ||
      design.note.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDesigns(results);
    setCurrentPage(1); // Reset to first page when search term changes
  }, [searchTerm, designs]);

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredDesigns.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredDesigns.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const DeleteDesign = async (did) => {
    try {
      // Send delete request to the server
      const response = await axios.delete(`${baseURL}/delete-design?did=${did}`);
      
      // Check if the response status is 200 (success)
      if (response.status === 200) {
        // Remove the deleted design from the state
        setDesigns(designs.filter(design => design.did !== did));
        setFilteredDesigns(filteredDesigns.filter(design => design.did !== did));
        
        // Show success notification
        alert('Design deleted successfully');
      } else {
        // Show failure notification if status is not 200
        alert('Failed to delete design');
      }
    } catch (error) {
      // Show error notification
      alert('An error occurred while deleting the design');
      console.error('Error deleting design:', error);
    }
  };

  return (
    <AdminLayout>
      <div className="container">
        <h3>Design List</h3>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
          <>
            <table className="design-table">
              <thead>
                <tr>
                  <th>Sr. NO</th>
                  <th>Product Name</th>
                  <th>Design Name</th>
                  <th>Images</th>
                  <th>Size</th>
                  
                  <th>Printing Side</th>
                  <th>Paper Type</th>
                  <th>Foil</th>
                  <th>Note</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length > 0 ? (
                  currentItems.map((design) => (
                    <tr key={design.did}>
                      <td>{design.did}</td>
                      <td>{design.pname}</td>
                      <td>{design.dname}</td>
                      <td>
                        <img src={`${baseURL}/images/${design.images}`} alt={design.dname} style={{ width: '100px' }} />
                      </td>
                      <td>{design.size}</td>
                      <td>{design.printing_side}</td>
                      <td>{design.paper_type}</td>
                      <td>{design.foil}</td>
                      <td>{design.note}</td>
                    
                      <td><Link to={`/designs/edit/${design.did}`}>Edit</Link></td>
                      <td>
                        <p onClick={() => DeleteDesign(design.did)} style={{ cursor: 'pointer', color: 'red' }}>Delete</p>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10">No designs found</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="pagination">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span>Page {currentPage} of {totalPages}</span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </>
        )}
      </div>
    </AdminLayout>
  );
};

export default DesignTable;
