import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Header from './header';
import Top from './top';

const DesignDetails = () => {
  const { did } = useParams(); // Design ID from route parameters
  const [design, setDesign] = useState(null);
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notification, setNotification] = useState(null);
  const navigate = useNavigate();

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchDesignAndProductDetails = async () => {
      try {
        // Fetch design details
        const designResponse = await axios.get(`${baseURL}/preview/${did}`);
        const designData = designResponse.data.designs && designResponse.data.designs[0];
        if (designData) {
          setDesign(designData);

          // Fetch product details based on design's product ID
          const productResponse = await axios.get(`${baseURL}/product/${designData.product}`);
          setProduct(productResponse.data);
        } else {
          setDesign(null);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchDesignAndProductDetails();
  }, [did]);

  const handleAddToCart = async () => {
    if (!quantity) {
      setNotification('Quantity is required.');
      setTimeout(() => setNotification(null), 3000); // Hide notification after 3 seconds
      return;
    }
    try {
      const cartData = {
        pid: design.product,
        did: design.did,
        quantity: quantity,
      };

      const response = await axios.post(`${baseURL}/cart-add`, cartData);

      if (response.status === 200) {
        navigate('/cart');
      } else {
        alert('Failed to add to cart.');
      }
    } catch (err) {
      console.error('Error adding to cart:', err);
      alert('An error occurred while adding to cart.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <Header />
      <Top />
      <div className="container">
      {product && (
                   <div className='container ul-container'>
                   <ul className='ul-list-preview'>
                   <li><Link to={`/home`}>Home</Link></li>
                   <li>&nbsp; /</li>
                   <li>&nbsp;<Link to={`/design/${product.pid}`}>{product.pname}</Link> </li>
                   <li>&nbsp; /</li>
                   <li>&nbsp;{design.dname}</li>
                   </ul>
                 </div>
                )}
        <div className="row preview-row">
          <div className="col-sm-5 images-preview-div">
            {design && (
              <img
                src={`${baseURL}/images/${design.images}`}
                alt={design.dname}
                className="product-image-preview "
              />
            )}
          </div>
          <div className="col-sm-7">
            {design ? (
              <div className='desc'>
                <p className='preview-design-name'>{design.dname}</p>
                <div className='form-group row'>
                  <div className='col-sm-4'>
                    <label>Size</label>
                  </div>
                  <div className='col-sm-6'>
                    <input type='text' className='form-control' value={design.size} readOnly />
                  </div>
                </div>
                <div className='form-group row'>
                  <div className='col-sm-4'>
                    <label>Paper Type</label>
                  </div>
                  <div className='col-sm-6'>
                    <input type='text' className='form-control' value={design.paper_type} readOnly />
                  </div>
                </div>
                {design.foil && (
                  <div className='form-group row'>
                    <div className='col-sm-4'>
                      <label>Foil</label>
                    </div>
                    <div className='col-sm-6'>
                      <input type='text' className='form-control' value={design.foil} readOnly />
                    </div>
                  </div>
                )}
               
                <div className='form-group row'>
                  <div className='col-sm-4'>
                    <label>Quantity</label>
                  </div>
                  <div className='col-sm-6'>
                    <input
                      type='text'
                      className='form-control qty-text'
                      name='quantity'
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                      required
                    />
                    <p className='notification-preview'>{notification}</p>
                  </div>
                </div>
                <input type='hidden' className='form-control' name='pid' value={design.product} />
                <input type='hidden' className='form-control' name='did' value={design.did} />
                <div className='form-group row button-row-div'>
                  <button className='btn-cart' onClick={handleAddToCart}>
                    Add To Cart
                  </button>
                </div>
              </div>
            ) : (
              <div>No design found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignDetails;
